.title {
    margin-left: calc(5px + 1vmin);
    margin-top: 6%;
    font-size: calc(20px + 1vmin);
    font-weight: 500;
}

.logo {
    width: calc(25px + 2vmin);
    height: calc(25px + 2vmin);
}

.login-form-button {
    display: grid;
    vertical-align: bottom;
    width: 100%;
}

.bottom-form {
    display: table;
    width: calc(200px + 20vmin);
}

.login-form-forgot {
    float: right;
}

.login-form-remember {
    float: left;
    color: black;
    padding-bottom: calc(3px + 2vmin);
}

.text-field {
    margin-bottom: 3%;
}

#components-form-demo-normal-login .login-form {
    max-width: 500px;
}

#components-form-demo-normal-login .login-form-forgot {
    float: right;
}
