@import '~antd/dist/antd.less';

.above_modal {
    z-index: 10001;
    pointer-events: none;
}

.ant-select-dropdown-menu {
    max-height: 50vh;
}

.loginHeader {
    display: table;
    padding-bottom: 1%;
}

.header {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.cell {
    display: table-cell;
    vertical-align: middle;
}

.App {
    height: 100%;
    width: 100%;
    text-align: center;
}

.App-header {
    background-color: #f0f0f0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #3d3d3d;
}

h1 {
    font-size: 175%;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
}

h2 {
    font-size: 133%;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
}

h3 {
    font-size: 120%;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: none !important;
}

@primary-color: #E11E2F;@font-size-base: 16px;