canvas ~ #root .ant-layout {
    background: none !important;
}

body {
    margin: 0;
    font-family: 'Rubik', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::selection {
    background-color: #1890ff !important;
}

.ant-switch-checked {
    background-color: #FFC42D !important;
}